import React from "react";
import Footer from "../../components/Footer/Footer";

const Blog = () => {
    return (
        <div>
            {/* <Header /> */}
            <main className="center-content">
                <h2>Blog</h2>
            </main>
            <Footer />
        </div>
    );
};

export default Blog;
