import React from "react";
import Footer from "../../components/Footer/Footer";
import '../TermsPage/TermsPage.module.css';

const Privacy = () => {
    const termsPath = process.env.PUBLIC_URL + "/terms.html";
    return (
        <div>
            <main style={{ padding: '20px' }}>
                <h1>Terms of Use</h1>
                <p>Last Updated: 05/16/2024</p>
                <iframe 
                    src={termsPath} 
                    title="Terms of Use"
                    style={{ width: '100%', height: 'calc(100vh - 40px)', border: 'none' }} // Inline styles for specific adjustments
                ></iframe>
            </main>
            <Footer />
        </div>
    );
};

export default Privacy;
