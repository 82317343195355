import React, { Component } from 'react';
import styles from './Footer.module.css';

class SocialMedia extends Component {
    render() {
        return (
            <div className={styles.socialIcons}>
                    <a href="https://instagram.com">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48" height="48" alt="Instagram" className={styles.socialIcon}>
                            <path d="M34.09,2H13.91A11.92,11.92,0,0,0,2,13.91V34.09A11.92,11.92,0,0,0,13.91,46H34.09A11.92,11.92,0,0,0,46,34.09V13.91A11.92,11.92,0,0,0,34.09,2ZM40,34.09a5.91,5.91,0,0,1-5.91,5.91H13.91A5.91,5.91,0,0,1,8,34.09V13.91A5.91,5.91,0,0,1,13.91,8H34.09A5.91,5.91,0,0,1,40,13.91Z" />
                            <path d="M24,12A12,12,0,1,0,36,24,12,12,0,0,0,24,12Zm0,19.6A7.6,7.6,0,1,1,31.6,24,7.6,7.6,0,0,1,24,31.6Z" />
                            <circle cx="36.5" cy="11.5" r="2.5" />
                        </svg>
                    </a>
                    <a href="https://facebook.com">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" alt="Facebook" className={styles.socialIcon}>
                            <path fill="#000000" d="M24 4C12.95 4 4 12.95 4 24c0 9.8 7.09 17.88 16.3 19.38V29.78h-4.9V24h4.9v-3.96c0-4.84 2.96-7.48 7.29-7.48 2.08 0 3.87.16 4.39.23v5.09h-3.01c-2.37 0-2.83 1.13-2.83 2.78V24h5.64l-.74 5.78h-4.9V43.4C40.92 41.88 48 33.8 48 24c0-11.05-8.95-20-20-20z" />
                        </svg>
                    </a>
                    <a href="https://twitter.com">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 50 50" alt="Twitter" className={styles.socialIcon}>
                            <path fill="#000000" d="M11,4c-3.866,0 -7,3.134 -7,7v28c0,3.866 3.134,7 7,7h28c3.866,0 7,-3.134 7,-7v-28c0,-3.866 -3.134,-7 -7,-7zM13.08594,13h7.9375l5.63672,8.00977l6.83984,-8.00977h2.5l-8.21094,9.61328l10.125,14.38672h-7.93555l-6.54102,-9.29297l-7.9375,9.29297h-2.5l9.30859,-10.89648zM16.91406,15l14.10742,20h3.06445l-14.10742,-20z" />
                        </svg>
                    </a>
                    <a href="https://linkedin.com" className="socialIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48" height="48" alt="LinkedIn" className={styles.socialIcon}>
                            <path fill="#000000" d="M42,37c0,2.762-2.238,5-5,5H11c-2.762,0-5-2.238-5-5V11c0-2.762,2.238-5,5-5h26c2.762,0,5,2.238,5,5v26z" />
                            <path fill="#ffffff" d="M12,19h5v17h-5zm2.485-2h-.028c-1.492,0-2.457-1.112-2.457-2.501 0-1.419,0.995-2.499,2.514-2.499 1.521,0,2.458,1.08,2.486,2.499 0,1.388-.965,2.501-2.515,2.501zm21.515,21h-5V27.901c0-2.198-1.225-3.698-3.192-3.698-1.501,0-2.313,1.012-2.707,1.99-.144,0.35-.101,1.318-.101,1.807v9h-5V19h5v2.616c0.721-1.116,1.85-2.616,4.738-2.616 3.578,0,6.261,2.25,6.261,7.274v9.726z" />
                        </svg>
                    </a>
                    </div>
        );
    }
}

export default SocialMedia;