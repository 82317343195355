import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import styles from "./FAQ.module.css";

const FAQ = () => {
    return (
        <>
            {/* <Navbar /> */}
            <Header />
            <div className={styles.faqContainer}>
                <h2>Frequently Asked Questions</h2>
                <div className={styles.faqList}>
                    <h3>General Questions</h3>
                    <p><strong>1. What is the main purpose of this product?</strong><br />
                        Our product streamlines the process of locating buildings that pay commissions for rental referrals, known as co-operating or co-op buildings.</p>

                    <p><strong>2. Who can benefit from using this product?</strong><br />
                        Real estate agents, brokers, property managers, and many more real estate affiliated entities and professionals as they see fit.</p>

                    <h3>Features and Functionality</h3>
                    <p><strong>3. What features does the product offer?</strong><br />
                        The product offers an easy-to-use way of discovering if a building you’ve encountered cooperates with locators. We do this through a text-based search that you always have access to at our phone number .</p>

                    <p><strong>4. Where are you available?</strong><br />
                        We are available at  for on-the-go access on all platforms and mobile devices.</p>

                    <h3>Usage and Support</h3>
                    <p><strong>6. How do I get started with the product?</strong><br />
                        You text us the building address you want checked for cooperation at , and we will text you the cooperating status, cooperating terms, cooperation percentage, and when we had this information last updated in our system.
                        You will be prompted to sign up after you’ve used our product a few times and are satisfied with the results.
                        Alternatively, you can sign up on our website, and text us and follow the same steps and you will have the information you need!</p>

                    <p><strong>7. What kind of customer support is available?</strong><br />
                        We offer customer support via chat, email, and phone.</p>

                    <h3>Pricing and Plans</h3>
                    <p><strong>9. What are the pricing plans available?</strong><br />
                        We currently only offer one tier but plan to offer more based on developing new features and products. Visit our pricing page for more detailed and up-to-date information.</p>

                    <p><strong>10. Is there a free trial available?</strong><br />
                        Yes, we offer several texts to our number at  free to all new users.</p>

                    <p><strong>11. Are there any hidden fees?</strong><br />
                        No, our pricing is transparent with no hidden fees.</p>

                    <h3>Security and Privacy</h3>
                    <p><strong>12. How is my data protected?</strong><br />
                        We use industry-standard encryption and security practices to ensure your data is safe.</p>

                    <p><strong>13. Do you comply with GDPR and other data protection regulations?</strong><br />
                        Yes, we are fully compliant with GDPR and other relevant regulations.</p>

                    <h3>Troubleshooting</h3>
                    <p><strong>18. What should I do if I encounter a problem?</strong><br />
                        Contact our support team at contact@realtor-buddy.com.</p>

                    <p><strong>19. How do I report a bug or provide feedback?</strong><br />
                        You can report bugs or provide feedback directly at contact@realtor-buddy.com.</p>

                    <h3>Miscellaneous</h3>
                    <p><strong>20. How frequently is the product updated?</strong><br />
                        We regularly update our product with new features, improvements, and security patches.</p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default FAQ;
