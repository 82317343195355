import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Navbar.module.css';

const Navbar = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isResourcesDropdownOpen, setResourcesDropdownOpen] = useState(false);
    const [isCompanyDropdownOpen, setCompanyDropdownOpen] = useState(false);

    const resourcesDropdownRef = useRef(null);
    const companyDropdownRef = useRef(null);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
        // Close dropdowns when mobile menu is toggled
        setResourcesDropdownOpen(false);
        setCompanyDropdownOpen(false);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
        // Close dropdowns when mobile menu is closed
        setResourcesDropdownOpen(false);
        setCompanyDropdownOpen(false);
    };

    const toggleResourcesDropdown = (e) => {
        e.stopPropagation();
        setResourcesDropdownOpen(!isResourcesDropdownOpen);
        setCompanyDropdownOpen(false); // Close other dropdown if open
    };

    const toggleCompanyDropdown = (e) => {
        e.stopPropagation();
        setCompanyDropdownOpen(!isCompanyDropdownOpen);
        setResourcesDropdownOpen(false); // Close other dropdown if open
    };

    const handleClickOutside = (event) => {
        if (
            resourcesDropdownRef.current &&
            !resourcesDropdownRef.current.contains(event.target)
        ) {
            setResourcesDropdownOpen(false);
        }
        if (
            companyDropdownRef.current &&
            !companyDropdownRef.current.contains(event.target)
        ) {
            setCompanyDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.navbarContainer}>
            <nav className={styles.navbar}>
                <button className={styles.mobileMenuButton} onClick={toggleMobileMenu}>
                    ☰
                </button>
                <ul className={`${styles.navLinks} ${isMobileMenuOpen ? styles.mobileMenuOpen : ''}`}>
                    <li><a href="/" onClick={closeMobileMenu}>Home</a></li>
                    <li><a href="#about" onClick={closeMobileMenu}>About Us</a></li>
                    {/* <li><a href="#product" onClick={closeMobileMenu}>Product</a></li> */}
                    <li><a href="#pricing" onClick={closeMobileMenu}>Pricing</a></li>
                    {/* <li><a href="#reviews" onClick={closeMobileMenu}>Reviews</a></li> */}
                    <li className={styles.dropdown} ref={resourcesDropdownRef}>
                        <button className={styles.dropbtn} onClick={toggleResourcesDropdown}>
                            Resources
                        </button>
                        <div className={`${styles.dropdownContent} ${isResourcesDropdownOpen ? styles.dropdownOpen : ''}`}>
                            {/* <NavLink to="/blog" className={styles.navLink} onClick={closeMobileMenu}>Blog</NavLink> */}
                            {/* <NavLink to="/sitemap" className={styles.navLink} onClick={closeMobileMenu}>Sitemap</NavLink> */}
                            <NavLink to="/faqs" classsName={styles.navLink} onClick={closeMobileMenu}>FAQs</NavLink>
                        </div>
                    </li> 
                    <li className={styles.dropdown} ref={companyDropdownRef}>
                        <button className={styles.dropbtn} onClick={toggleCompanyDropdown}>
                            Company
                        </button>
                        <div className={`${styles.dropdownContent} ${isCompanyDropdownOpen ? styles.dropdownOpen : ''}`}>
                            <NavLink to="/privacy" className={styles.navLink} onClick={closeMobileMenu}>Privacy Policy</NavLink>
                            <NavLink to="/terms" className={styles.navLink} onClick={closeMobileMenu}>Terms of Service</NavLink>
                        </div>
                    </li>
                </ul>

                {isMobileMenuOpen && <div className={`${styles.overlay} ${styles.visible}`} onClick={closeMobileMenu}></div>}
            </nav>
        </div>
    );
};

export default Navbar;