import HomePage from '../../pages/HomePage/HomePage';
import TermsPage from '../../pages/TermsPage/TermsPage';
import Privacy from '../../pages/Privacy/Privacy';
import FAQ from '../../pages/FAQ/FAQ';
import Blog from '../../pages/Blog/Blog';


import React from 'react';
import { 
  createBrowserRouter, 
  createRoutesFromElements, 
  Route, 
  Navigate,
  RouterProvider } 
from 'react-router-dom';

/* TODO:
  - fix styling for phone number in about us section
  - make FAQ section 
*/

const router = createBrowserRouter(createRoutesFromElements(
  <Route>
  <Route path='/' element={<HomePage />} />
  <Route path='/terms' element={<TermsPage />} />
  <Route path='/privacy' element={<Privacy />} />
  <Route path='/faqs' element={<FAQ />} />
  <Route path='/blog' element={<Blog />} />
  {/* Catch-all route */}
  <Route path="*" element={<Navigate to="/" />} />
  </Route>
));

const App = () => {
  return (
    <RouterProvider router={router} />
  );
};

export default App;
