import React from "react";
import Logo from "../Icons/Logo";
import styles from './Header.module.css';
import { NavLink } from "react-router-dom";
import Navbar from "../Navbar/Navbar";

const Header = () => {
    return (
        <header className={styles.header}>
            <div className={styles.logoNavbarContainer}>
                <Navbar />
                <div className={styles.logoContainer}>
                    <NavLink to="/" rel="canonical">
                        <Logo className={styles.logo} />
                    </NavLink>
                </div>
            </div>
            <span className={styles.message}>
                Find cooperating buildings around you with a single text.
            </span>
        </header>
    );
};

export default Header;