import React from "react";
import Footer from "../../components/Footer/Footer";
import '../Privacy/Privacy.module.css';

const Privacy = () => {
    const privacyPolicyPath = process.env.PUBLIC_URL + "/privacy.html";
    return (
        <div>
            <main style={{ padding: '20px' }}>
                <h1>Privacy Policy</h1>
                <p>Last Updated: 05/16/2024</p>
                <iframe 
                    src={privacyPolicyPath}
                    title="Privacy Policy"
                    style={{ width: '100%', height: 'calc(100vh - 40px)', border: 'none' }} // Inline styles for specific adjustments
                ></iframe>
            </main>
            <Footer />
        </div>
    );
};

export default Privacy;
