import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../Icons/Logo";
import styles from './Footer.module.css';
import SocialMedia from "./SocialMedia";

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerLogo}>
                <div className={styles.footerColumn}>
                    <div className={styles.logoSVG}>
                    <NavLink to="/"><Logo /></NavLink>
                    </div>
                    <div className={styles.legal}>
                        <p>© {new Date().getFullYear()} EDI Dynamics LLC. All rights reserved.</p>
                        <p>1209 Mountain Rd, Albuquerque, NM 87107</p>
                        <p>EDI Dynamics LLC is a leading provider of AI-enabled solutions. For more information, please refer to our privacy policy and terms of service.</p>
                        <p>
                            Disclaimer: All information provided on this website is for informational purposes only and does not constitute
                            legal, financial, or professional advice. Please refer to our detailed disclaimers in our Terms of Service.
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.footerLinks}>
                <div className={styles.footerColumn}>
                    <h3>Resources</h3>
                    {/* <NavLink to="/blog" rel="canonical">Blog</NavLink> */}
                    {/* <NavLink to="/sitemap" rels="canonical">Sitemap</NavLink> */}
                    <NavLink to="/faqs" rel="canonical">FAQs</NavLink>
                </div>
                <div className={styles.footerColumn}>
                    <h3>Company</h3>
                    {/* <a href="#about" rel="canonical">About Us</a> */}
                    {/* <a href="#reviews" rel="canonical">Reviews</a> */}
                    <NavLink to="/privacy" rel="canonical">Privacy Policy</NavLink>
                    <NavLink to="/terms" rel="canonical">Terms of Service</NavLink>
                </div>
                <div className={styles.footerColumn}>
                    <h3>Contact Us</h3>
                    {/* <a href="tel:323-856-55455" rel="canonical">323-856-5545</a> */}
                    <a href="mailto:contact@realtor-buddy.com" rel="canonical">contact@realtor-buddy.com</a>
                    <div className={styles.socialMedia}>
                        <h3>Follow Us</h3>
                        <SocialMedia />
                    </div>
                </div>
            </div>


        </footer>
    );
};

export default Footer;