import Header from '../../components/Header/Header';
import Banner from '../../components/Banner/Banner';
import Footer from '../../components/Footer/Footer';
import Pricing from '../../components/Pricing/Pricing';
import AboutUs from '../../components/AboutUs/AboutUs';

function HomePage() {
  return (
    <div>
      <Header />
      <Banner />
      <section id='about'>
          <AboutUs />
      </section>
      <section id='pricing'>
        <Pricing />
      </section>
      <section id='reviews'>
        {/* <Reviews /> */}
      </section>
      <Footer />
    </div>
  );
}

export default HomePage;
