import React from 'react';
import StripeButton from '../StripeButton/StripeButton';
import styles from './Pricing.module.css';

const Pricing = () => {
    return (
        <div className={styles.pricingContainer}>
            <h2>Pricing and Subscription</h2>
            <div className={styles.pricingAndFeatures}>

                <div className={styles.pricingBox}>
                    <div className={styles.price}>
                        <span className={styles.dollar}>$</span>
                        <span className={styles.amount}>25</span>
                        <span className={styles.per}>/mo</span>
                    </div>
                    <ul className={styles.featuresList}>
                        <li>Unlimited building inquiries</li>
                        <li>Live commission details</li>
                        <li>Extensive U.S Building Data</li>
                        <li>Intuitive SMS interface</li>
                    </ul>
                    <StripeButton />
                </div>

                <div className={styles.additionalFeatures}>
                    <ul className={styles.additionalFeaturesList}>
                        <li>Send <strong>unlimited</strong> building inquiries for <strong>no extra fee</strong>.</li>
                        <li>Recieve <strong>immediate, detailed </strong>commission rates in <strong>real-time</strong>.</li>
                        <li><strong>Comprehensive</strong> buildings database across the <strong>United States</strong>.</li>
                        <li>Simple <strong>SMS</strong> interface for inquiries; <strong>no app needed.</strong></li>
                        <li>Instant <strong>support</strong> from our team through <strong>email</strong>.</li>
                        <li>Regular <strong>updates</strong> and <strong>new features</strong> added.</li>
                        <li>First 3 inquiries are <strong>free</strong> of charge.</li>
                    </ul>

                </div>
            </div>
        </div>
    );
};

export default Pricing;
