import React from "react";
import styles from "./AboutUs.module.css";

const AboutUs = () => {
    return (
        <div className={styles.aboutUs}>
            <h1 style={{ textAlign: "center" }}>About Us</h1>
            <div className={styles.textContainer}>
                <p> Realtor Buddy is designed by realtors, for realtors, to streamline your property searches with AI-driven
                    insights. We maintain an extensive database of buildings across the United States, focusing on the details
                    that matter most to you - the realtor - so you can focus on meeting your clients' needs. </p>

                <p>Our service is simple yet effective. Instead of an app, we use what's already integral to your workflow:
                    your phone. Just text us the name of a building you're interested in, and we'll immediately respond with
                    key information, including whether it offers commissions to locators. </p>

                <p>With Realtor Buddy, making informed decisions is easier than ever—because we understand that in real estate, every moment counts.
                    Text your first few building queries to <a href="sms:+16562203831" aria-label="Send a text message to Realtor Buddy">(656) 220-3831</a> for free to try us.</p>
            </div>
        </div>
    );
};

export default AboutUs;
