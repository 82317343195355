import React, { useEffect } from "react";


const StripeButton = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/buy-button.js'
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }

    }, []);

    return (
        <div id="stripe-buy-button-container">
            <stripe-buy-button
                buy-button-id="buy_btn_1PKgRtRsFyaLrmGCHUA3YSuP"
                publishable-key="pk_live_51P93Z3RsFyaLrmGCb6juC6vTiNxCJtp3sYoDuxNCrEmU0jlPgjiOXN6u48cqOom5tzgTDNmdf6EAtMkc9j56i4rs004aHGFIra"
            >
      </stripe-buy-button>
        </div>
    );
};

export default StripeButton;