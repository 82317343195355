import React from "react";
import styles from './Banner.module.css';
import PhoneNumber from "../Icons/PhoneNumber";
import ChatBubbleDuo from "../Icons/ChatBubbleDuo";
import Building from "../Icons/Building";

/* TODO: 18/05/24
*/

const Banner = () => {
    return (
        <div className={styles.banner}>
            <h1>How does it work?</h1>
            <div className={styles.row}>
                    <div className={styles.building}>
                        <Building />
                    </div>
                    <p className={styles.buildingText}>Need to find out if a building co-operates?</p>
            </div>
            <div className={styles.row}>
                <p className={styles.phoneText}>Text us the building name and we'll find out for you!</p>
                <img className={styles.phone} width="125" height="100%" src="https://img.icons8.com/ios/100/iphone14-pro.png" alt="iphone14-pro"/>
            </div>
            <div className={styles.row}>
                <div className={styles.messageContainer}>
                    <div className={styles.messageIcons}>
                        <ChatBubbleDuo />
                    </div>
                    <div className={styles.messageText}>
                        <p>Just like that... you'll have your answer</p>
                    </div>
                </div>
            </div>
            <div className={styles.phoneNumberContainer}>
                <h2>Try us now!</h2>
                <a href="sms:+16562203831"><PhoneNumber /></a>
            </div>
        </div>
    );
};

export default Banner;